module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:500,600,700:latin-ext","Gelasio:500i,700:latin-ext"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/buildhome/repo/src/locales","languages":["pl","en"],"defaultLanguage":"pl","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Agnieszka Kazmierczyk","short_name":"agnieszkakazmierczyk","start_url":"/","background_color":"#F8F8F8","theme_color":"#14171C","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30ca0f7c9fd397c2496124c116ec3bae"},
    }]
